import React, { createContext, useState, useRef, useLayoutEffect } from 'react';
import loop from '../assets/loop.mp3';
import { gsap } from 'gsap';

export const AudioContext = createContext({
    isPlaying: false,
    play: () => {},
    pause: () => {},
  });

export const AudioProvider = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  if (!audioRef.current) {
    audioRef.current = new Audio(loop);
    audioRef.current.loop = true;
    audioRef.current.volume = 0;
    audioRef.current.playbackRate = 1;
  }

  const fadeAudio = (targetVolume, onComplete) => {
    gsap.to(audioRef.current, {
      volume: targetVolume,
      duration: 1,
      ease: 'power1.inOut',
      onComplete,
    });
  };

  const play = () => {
    if (!audioRef.current) return;
    audioRef.current.play();
    fadeAudio(1);
    setIsPlaying(true);
  };

  const pause = () => {
    if (!audioRef.current) return;
    fadeAudio(0, () => audioRef.current.pause());
    setIsPlaying(false);
  };

  const contextValue = {
    isPlaying,
    setIsPlaying,
    play,
    pause,
    audio: audioRef.current,
  };

  return <AudioContext.Provider value={contextValue}>{children}</AudioContext.Provider>;
};
